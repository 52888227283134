import React from 'react'

import ApplicationLayout from 'components/layouts/preliminary-application-layout'
import ContainedButton from 'components/buttons/contained-button'

export default ({ location }) => {
  return (
    <ApplicationLayout location={location}>
      <div className="my-10 flex flex-col items-center px-4">
        <p className="mb-6 text-center">Please complete the entire form</p>
        <a href={`${location.pathname}/personal-information`}>
          <ContainedButton>START</ContainedButton>
        </a>
      </div>
    </ApplicationLayout>
  )
}
